import { nextTick } from 'vue'
import { toPng } from 'html-to-image'
import useReview, { ReviewKey } from '../../use-review'

export const generatePrismaString = async (
  review: ReturnType<typeof useReview>,
): Promise<string> => {
  if (!review) {
    throw new Error('Review context not available')
  }

  const tempDiv = document.createElement('div')
  tempDiv.style.position = 'fixed'
  tempDiv.style.left = '-9999px'
  tempDiv.style.top = '-9999px'
  tempDiv.style.width = 'auto'
  document.body.appendChild(tempDiv)

  try {
    const { createApp } = await import('vue')
    const { default: PrismaHtmlComponent } = await import(
      '../utils/PrismaHtml.vue'
    )

    const app = createApp(PrismaHtmlComponent)
    app.provide(ReviewKey, review)
    app.mount(tempDiv)

    await nextTick()
    await new Promise((resolve) => setTimeout(resolve, 500))

    const contentElement = tempDiv.firstChild as HTMLElement
    if (!contentElement) {
      throw new Error('PRISMA content failed to render')
    }

    await waitForImagesToLoad(contentElement)

    return await toPng(contentElement, {
      width: contentElement.scrollWidth,
      height: contentElement.scrollHeight,
      backgroundColor: '#ffffff',
      pixelRatio: 2,
      cacheBust: true,
    })
  } finally {
    document.body.removeChild(tempDiv)
  }
}

const waitForImagesToLoad = async (element: HTMLElement) => {
  await Promise.all(
    Array.from(element.querySelectorAll('img')).map((img) =>
      img.complete
        ? Promise.resolve()
        : new Promise((resolve) => {
            img.onload = resolve
            img.onerror = resolve
          }),
    ),
  )
  await new Promise((resolve) => setTimeout(resolve, 200))
}
