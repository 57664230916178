import { StudyState } from '@app/types'
import { PeerReviewStatus } from '../types/peerReview.type'
import { StudyDesignStatus } from '../types/studyDesignStatus.type'
import { Attribute } from './attributes.model'
import { FileMeta } from './file-meta.model'
import { ReviewItem } from './reviewItem.model'
import { Search } from './search.model'
import { StudyDesign } from './studyDesign.model'
import { INCLUDED } from '../types/included-screening-criterion.type'
import { ReviewItemType } from '../types/reviewItemType.type'
import { GuidanceMetadata } from './reviewItemMetadata/guidancMetadata'

export class Guidance extends ReviewItem {
  metadata: GuidanceMetadata
  constructor({
    complaintDate,
    search,
    appraisal,
    fullTextScreening,
    id,
    isFavorite,
    maybeParentStudyId,
    oxfordLevelOfEvidence,
    parentStudyId,
    pdfFile,
    peerReviewStatus,
    synthesis,
    titleAndAbstractScreening,
    studyDesign,
    studyDesignStatus,
    metadata,
  }: {
    id: number
    complaintDate?: Date
    search: Search
    appraisal?: { [key: string]: string }
    fullTextScreening?: string
    isFavorite?: boolean
    maybeParentStudyId?: number
    oxfordLevelOfEvidence?: string
    parentStudyId?: number
    pdfFile?: FileMeta
    peerReviewStatus?: PeerReviewStatus
    synthesis?: { attributes: Attribute[] }
    titleAndAbstractScreening?: string
    studyDesign?: StudyDesign
    studyDesignStatus?: StudyDesignStatus
    metadata?: GuidanceMetadata
  }) {
    super({
      complaintDate,
      search,
      appraisal,
      fullTextScreening,
      id,
      isFavorite,
      maybeParentStudyId,
      oxfordLevelOfEvidence,
      parentStudyId,
      pdfFile,
      peerReviewStatus,
      synthesis,
      titleAndAbstractScreening,
      studyDesign,
      studyDesignStatus,
    })
    this.metadata = new GuidanceMetadata(metadata ?? {})
    this.type = ReviewItemType.Guidance
  }

  get state(): StudyState {
    if (this.isDuplicate) return StudyState.DUPLICATE
    if (this.fullTextScreening === INCLUDED) return StudyState.INCLUDED
    if (
      (this.titleAndAbstractScreening &&
        this.titleAndAbstractScreening != INCLUDED) ||
      (this.fullTextScreening && this.fullTextScreening !== INCLUDED)
    )
      return StudyState.EXCLUDED
    return StudyState.UNSCREENED
  }
}
