<template>
  <Modal v-if="isAdmin" ref="modal" position="center">
    <template #activator="{ show }">
      <button
        class="px-4 py-3 flex items-center gap-2 hover:bg-light-neutral-500 w-full"
        @click="show"
      >
        <TrashIcon class="size-5 text-error-500" />
        <a
          class="font-medium text-dark-neutral-500 relative text-sm text-error-500"
        >
          Delete Review
        </a>
      </button>
    </template>

    <template #header="{ hide }">
      <p
        class="text-primary text-lg font-semibold capitalize text-center flex gap-2 items-center"
      >
        Delete Review
      </p>
      <button
        class="bg-light-neutral-500 rounded-full h-[30px] w-[30px] flex items-center justify-center"
        @click="hide"
      >
        <XIcon class="size-4 stroke-2 text-mid-neutral-500" />
      </button>
    </template>

    <template #content>
      <p class="text-start text-dark-neutral-500">
        You are deleting a review from your plan.<br />
        <span class="font-bold">WARNING</span>: deleted reviews cannot be
        restored or recovered.
      </p>
    </template>

    <template #footer="{ hide }">
      <div class="flex justify-end gap-2">
        <ActionButton color="neutral" @click="hide"> Cancel </ActionButton>
        <ActionButton
          color="error"
          @click="deleteReview(project.id!, review.id)"
        >
          Delete
        </ActionButton>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { SnackbarState } from '@app/types'
import useSnackbar from '@app/composables/use-snackbar'
import { computed, onMounted, ref, nextTick } from 'vue'
import { Id } from '@core/domain/types/id.type'
import { hideAll } from 'tippy.js'
import useLoading from '@app/composables/use-loading'
import useProjects from '@app/views/Projects/use-projects'

import { HttpException } from '@core/exceptions/http.exception'
import { errorMessage } from '@app/utils/error-message'
import { Role } from '@core/domain/types/role.type'
import { injectStrict } from '@app/utils/injectStrict'
import { AuthKey } from '@app/injectionKeys'
import { Review } from '@core/domain/models/review.model'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { Project } from '@core/domain/models/project.model'
import Modal from '@app/components/Global/Modal/Modal.vue'
import XIcon from '@app/components/Icons/XIcon.vue'
import ActionButton from '@app/components/Global/ActionButton.vue'

const props = defineProps<{ review: Review; project: Project }>()

const snackbar = useSnackbar()
const loading = useLoading()
const projects = useProjects()
const { user } = injectStrict(AuthKey)

const selectedReviewsIds = ref<Id[]>([])

function resetSelectedReviewsIds() {
  selectedReviewsIds.value = []
}

async function deleteReview(projectId: Id, reviewId: number) {
  try {
    loading.start()

  
      await projects.deleteSelectedReviews(projectId, reviewId)
      resetSelectedReviewsIds()
      snackbar.show(SnackbarState.SUCCESS, 'Reviews deleted successfully')
      hideAll()
    
  } catch (e) {
    const error = e as HttpException
    snackbar.show(SnackbarState.ERROR, errorMessage(error.response.data))
    if (error.response.data.statusCode >= 500) {
      throw e
    }
  } finally {
    loading.stop()
  }
}

const shouldRender = ref(false)
onMounted(() => {
  nextTick(() => {
    const element = document.querySelector(`#project-${props.project.id}`)!
    const callback: IntersectionObserverCallback = (entries, observer) => {
      if (!entries[0].isIntersecting) return
      observer.unobserve(element)
      shouldRender.value = true
    }
    const observer = new IntersectionObserver(callback)
    observer.observe(element)
  })
})

const isAdmin = computed(() => user.value?.role === Role.ADMIN)
</script>
