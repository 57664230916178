import { IncidentMetadata } from '@core/domain/models/reviewItemMetadata/incidentMetadata'
import axios from 'axios'

export class MdidApiClient {
  private readonly baseUrl = 'https://rest.hres.ca/mdi/'

  async searchArticles(query: string): Promise<IncidentMetadata[]> {
    return this.searchRecords(query)
  }

  private async searchRecords(query: string): Promise<IncidentMetadata[]> {
    let incidents: IncidentMetadata[] = []
    const url = `${this.baseUrl}mdi_search?or=(incident-%3E%3Ecompany_name.plfts.*${query}*,incident-%3E%3Etrade_name.plfts.*${query}*)&limit=600`
    try {
      const response = await axios.get(url)
      incidents = response.data.map((record: any) => this.mapToIncident(record))
    } catch (error) {
      console.error('Error fetching records from MDID API:', error)
      return [] // Return an empty array on failure
    }

    return incidents
  }

  private mapToIncident(record: any): IncidentMetadata {
    return new IncidentMetadata({
      title: record.incident.trade_name.map((name: string) => name).join(', '),
      reportNumber: record.incident.incident_id,
      eventDate: record.incident.receipt_date,
      eventType: record.incident.incident_type_e,
      reportDate: record.incident.receipt_date,
      url: '',
      summary: record.incident.problem_detail
        .map((problemDetail: any) => problemDetail.desc_e)
        .join(', '),
    })
  }
}
