import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { OxfordLevelOfEvidenceType } from '@core/domain/types/oxford-level-of-evidence-type'
import { ReviewsRepository } from '@infrastructure/datasource/reviews.repository'
import { MetaData } from '@core/domain/types/meta-data.type'
import { AttributeStructure } from '@core/domain/models/data-extraction-plan-attribute'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { PdfStudyRetrievalStatus } from '@core/domain/types/PdfStudyRetrievalStatus'
import { InclusionCriterion } from '@core/domain/models/InclusionCriterion.model'
import { PeerReviewStatus } from '@core/domain/types/peerReview.type'
import { StudyDesignStatus } from '@core/domain/types/studyDesignStatus.type'
import { FileMeta } from '@core/domain/models/file-meta.model'
import { StudyDesign } from '@core/domain/models/studyDesign.model'
import { Api } from '@infrastructure/datasource/review-manager-api'

export class ReviewsService {
  constructor(
    private readonly _reviewsRepository: ReviewsRepository,
    private _reviewManagerService: Api<unknown>,
  ) {}

  async findById(reviewId: Id) {
    const review = await this._reviewsRepository.findById(reviewId)
    return review
  }

  async markStudyAsDuplicate(
    reviewId: Id,
    studyId: Id,
    parentStudyId: Id,
  ): Promise<void> {
    return this._reviewsRepository.markStudyAsDuplicate(
      reviewId,
      studyId,
      parentStudyId,
    )
  }

  async markStudyAsNotDuplicate(reviewId: Id, studyId: Id): Promise<void> {
    return this._reviewsRepository.markStudyAsNotDuplicate(reviewId, studyId)
  }

  async setStudyTitleAndAbstractScreening(
    reviewId: Id,
    studyId: Id,
    titleAndAbstractScreening: string,
  ) {
    return this._reviewsRepository.setStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
      titleAndAbstractScreening,
    )
  }

  async clearStudyTitleAndAbstractScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyTitleAndAbstractScreening(
      reviewId,
      studyId,
    )
  }

  async setStudyFullTextScreening(
    reviewId: Id,
    studyId: Id,
    fullTextScreening: string,
  ) {
    return this._reviewsRepository.setStudyFullTextScreening(
      reviewId,
      studyId,
      fullTextScreening,
    )
  }

  async clearStudyFullTextScreening(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyFullTextScreening(
      reviewId,
      studyId,
    )
  }

  async favoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.favoriteStudy(reviewId, studyId)
  }
  async unfavoriteStudy(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.unfavoriteStudy(reviewId, studyId)
  }

  async removeSearch(reviewId: Id, searchId: number) {
    return this._reviewsRepository.deleteSearchStudies(reviewId, searchId)
  }

  async planScreening(
    reviewId: Id,
    data: {
      titleAndAbstractCriteria: string[]
      fullTextCriteria: string[]
    },
  ) {
    return this._reviewsRepository.planScreening(reviewId, data)
  }

  async addFullTextCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.addFullTextCriterion(reviewId, criterion)
  }

  async deleteFullTextCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.deleteFullTextCriterion(reviewId, criterion)
  }

  async enableTitleAndAbstractScreening(reviewId: Id) {
    return this._reviewsRepository.enableTitleAndAbstractScreening(reviewId)
  }

  async disableTitleAndAbstractScreening(reviewId: Id) {
    return this._reviewsRepository.disableTitleAndAbstractScreening(reviewId)
  }

  async addTitleAndAbstractCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.addTitleAndAbstractCriterion(
      reviewId,
      criterion,
    )
  }

  async deleteTitleAndAbstractCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.deleteTitleAndAbstractCriterion(
      reviewId,
      criterion,
    )
  }

  async removeImportSourceFromPlan(reviewId: Id, importSourceId: string) {
    return this._reviewsRepository.removeImportSourceFromPlan(
      reviewId,
      importSourceId,
    )
  }

  async delete(reviewId: Id): Promise<void> {
    return this._reviewsRepository.delete(reviewId)
  }

  async findProject(reviewId: Id): Promise<Project> {
    return this._reviewsRepository.findProject(reviewId)
  }

  async updateName(reviewId: Id, name: string): Promise<void> {
    return this._reviewsRepository.updateName(reviewId, name)
  }

  async uploadStudyPdfFile(
    reviewId: Id,
    studyId: Id,
    file: File,
  ): Promise<FileMeta> {
    return this._reviewsRepository.uploadStudyPdfFile(reviewId, studyId, file)
  }

  async getStudyPdfFile(reviewId: Id, studyId: Id): Promise<Blob> {
    return this._reviewsRepository.getStudyPdfFile(reviewId, studyId)
  }

  async downloadSearchCitationFile(reviewId: Id, searchId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadSearchCitationFile(
      reviewId,
      searchId,
    )
  }

  async deleteStudyPdfFile(reviewId: Id, studyId: Id): Promise<void> {
    return this._reviewsRepository.deleteStudyPdfFile(reviewId, studyId)
  }

  async downloadPdfZip(reviewId: Id): Promise<Blob> {
    return this._reviewsRepository.downloadPdfZip(reviewId)
  }

  async editStudyAbstract(reviewId: Id, studyId: Id, abstract: string) {
    return this._reviewsRepository.editStudyAbstract(
      reviewId,
      studyId,
      abstract,
    )
  }

  async enableStudiesAppraisalImdrfMdce2019(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalImdrfMdce2019(reviewId)
  }

  async enableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async enablePicoInclusionCriteria(reviewId: Id) {
    return this._reviewsRepository.enablePicoInclusionCriteria(reviewId)
  }

  async disablePicoInclusionCriteria(reviewId: Id) {
    return this._reviewsRepository.disablePicoInclusionCriteria(reviewId)
  }

  async enableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    return this._reviewsRepository.enableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async disableStudiesAppraisal(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisal(reviewId)
  }

  async disableStudiesAppraisalOxfordLevelOfEvidence(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisalOxfordLevelOfEvidence(
      reviewId,
    )
  }

  async disableStudiesAppraisalPeerReviewStatus(reviewId: Id) {
    return this._reviewsRepository.disableStudiesAppraisalPeerReviewStatus(
      reviewId,
    )
  }

  async submitComplaint(
    reviewId: Id,
    studyId: Id,
    data: {
      body: string
      fromAddress: string
      fromName: string
      subject: string
      to: string
    },
  ) {
    return this._reviewsRepository.submitComplaint(reviewId, studyId, data)
  }

  async appraiseStudy(
    reviewId: Id,
    studyId: Id,
    appraisal: { criterionId: string; gradeId: string },
  ) {
    return this._reviewsRepository.appraiseStudy(reviewId, studyId, appraisal)
  }

  async setOxfordLevelOfEvidenceStudy(
    reviewId: Id,
    studyId: Id,
    evidence: OxfordLevelOfEvidenceType,
  ) {
    return this._reviewsRepository.setOxfordLevelOfEvidenceStudy(
      reviewId,
      studyId,
      evidence,
    )
  }

  async setStudyPeerReviewStatus(
    reviewId: Id,
    studyId: Id,
    peerReviewStatus: PeerReviewStatus,
  ) {
    return this._reviewsRepository.setStudyPeerReviewStatus(
      reviewId,
      studyId,
      peerReviewStatus,
    )
  }

  async setStudyDesignStatus(
    reviewId: Id,
    studyId: Id,
    studyDesignStatus: StudyDesignStatus,
  ) {
    return this._reviewsRepository.setStudyDesignStatus(
      reviewId,
      studyId,
      studyDesignStatus,
    )
  }

  async clearStudiesAppraisal(reviewId: Id, studyId: Id, criterionId: string) {
    return this._reviewsRepository.clearStudiesAppraisal(
      reviewId,
      studyId,
      criterionId,
    )
  }

  async cleaStudyOxfordLevelOfEvidence(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.cleaStudyOxfordLevelOfEvidence(
      reviewId,
      studyId,
    )
  }

  async clearStudyPeerReviewStatus(reviewId: Id, studyId: Id) {
    return this._reviewsRepository.clearStudyPeerReviewStatus(reviewId, studyId)
  }

  async updateStudy(reviewId: Id, studyId: Id, metaData: MetaData) {
    return this._reviewsRepository.updateStudy(reviewId, studyId, metaData)
  }

  async addAttributeStructure(
    reviewId: Id,
    data: { label: string; question: string; order: string },
  ) {
    return this._reviewsRepository.addAttributeStructure(reviewId, data)
  }

  async updateStudySynthesisAttribute(
    reviewId: Id,
    studyId: Id,
    data: { id: string; value: string },
  ) {
    return this._reviewsRepository.updateStudySynthesisAttribute(
      reviewId,
      studyId,
      data,
    )
  }

  async lockPlan(reviewId: Id) {
    return this._reviewsRepository.lockPlan(reviewId)
  }

  async unlockPlan(reviewId: Id) {
    return this._reviewsRepository.unlockPlan(reviewId)
  }

  async deleteAttributeStructure(reviewId: Id, attributeStructureId: string) {
    return this._reviewsRepository.deleteAttributeStructure(
      reviewId,
      attributeStructureId,
    )
  }

  async editAttributeStructure(
    reviewId: Id,
    updatedAttribute: AttributeStructure,
  ) {
    return this._reviewsRepository.editAttributeStructure(
      reviewId,
      updatedAttribute,
    )
  }

  async addCustomImportSourceToPlan(
    reviewId: Id,
    importSource: {
      id?: string
      name: string
      url: string
      type: ImportSourceType
      description?: string
    },
  ) {
    return this._reviewsRepository.addCustomImportSourceToPlan(
      reviewId,
      importSource,
    )
  }

  async importRisSearch({
    citationFiles,
    date,
    query,
    filters,
    reviewId,
    importSourceId,
  }: {
    query?: string
    filters?: string
    citationFiles: File[]
    date: string
    reviewId: number
    importSourceId: string
  }) {
    return this._reviewsRepository.importRisSearch({
      query,
      filters,
      citationFiles,
      date,
      reviewId,
      importSourceId,
    })
  }

  async importSearch({
    query,
    filters,
    date,
    reviewId,
    importSourceId,
    items,
  }: {
    query?: string
    filters?: string
    date: string
    reviewId: number
    importSourceId: string
    items: any[]
  }) {
    return this._reviewsRepository.importSearch({
      query,
      filters,
      date,
      reviewId,
      importSourceId,
      items,
    })
  }

  async importCitationSearch({
    citationFiles,
    reviewId,
    parentStudyId,
  }: {
    citationFiles: File[]
    reviewId: number
    parentStudyId: number
  }) {
    return this._reviewsRepository.importCitationSearch({
      citationFiles,
      reviewId,
      parentStudyId,
    })
  }

  async answerStudyQuestion(
    studyId: number,
    question: string,
  ): Promise<string> {
    return this._reviewsRepository.answerStudyQuestion(studyId, question)
  }

  async findRunningStudiesPdfImports(reviewId: number): Promise<
    {
      jobId: string
      status: PdfStudyRetrievalStatus
      studyId: number
    }[]
  > {
    return this._reviewsRepository.findRunningStudiesPdfImports(reviewId)
  }

  async addAppraisalCriteria(
    reviewId: number,
    data: { appraisalCriterionId: string; answer: string },
  ): Promise<void> {
    return this._reviewsRepository.addAppraisalCriteria(reviewId, data)
  }

  async addPicoInclusionCriterion(
    reviewId: Id,
    newCriterion: { criterionType: InclusionCriterion; criterion: string },
  ) {
    return this._reviewsRepository.addPicoInclusionCriterion(
      reviewId,
      newCriterion,
    )
  }

  async deletePicoInclusionCriterion(
    reviewId: Id,
    data: { criterionType: InclusionCriterion; criterion: string },
  ) {
    return this._reviewsRepository.deletePicoInclusionCriterion(reviewId, data)
  }

  async addInclusionCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.addInclusionCriterion(reviewId, criterion)
  }

  async deleteInclusionCriterion(reviewId: Id, criterion: string) {
    return this._reviewsRepository.deleteInclusionCriterion(reviewId, criterion)
  }

  async deleteAppraisalCriterionAnswer(
    reviewId: Id,
    data: { answerId: string; appraisalCriterionId: string },
  ) {
    return this._reviewsRepository.deleteAppraisalCriterionAnswer(
      reviewId,
      data,
    )
  }

  async updateStudyDesign(reviewId: Id, studyId: Id, studyDesign: StudyDesign) {
    return this._reviewsRepository.updateStudyDesign(
      reviewId,
      studyId,
      studyDesign,
    )
  }

  async lock(reviewId: Id): Promise<void> {
    return this._reviewsRepository.lockReview(reviewId)
  }

  async unlock(reviewId: Id): Promise<void> {
    return this._reviewsRepository.unlockReview(reviewId)
  }

  async archive(reviewId: Id): Promise<void> {
    return this._reviewsRepository.archiveReview(reviewId)
  }

  async unarchive(reviewId: Id): Promise<void> {
    return this._reviewsRepository.unarchiveReview(reviewId)
  }

  async updateSynthesisPlanSummary(reviewId: Id, summary: string) {
    return this._reviewsRepository.updateSynthesisPlanSummary(reviewId, summary)
  }

  async updateSearchStrategySummary(reviewId: Id, strategy: string) {
    return this._reviewsRepository.updateSearchStrategySummary(
      reviewId,
      strategy,
    )
  }

  async createEvaluator({
    reviewId,
    name,
    role,
  }: {
    reviewId: number
    name: string
    role: string
  }): Promise<number> {
    const { data } =
      await this._reviewManagerService.api.reviewsControllerCreateEvaluator(
        reviewId,
        {
          name,
          role,
        },
      )
    return data
  }

  async deleteEvaluator({
    reviewId,
    evaluatorId,
  }: {
    reviewId: number
    evaluatorId: number
  }): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerDeleteEvaluator(
      reviewId,
      evaluatorId,
    )
  }

  async updateEvaluator({
    reviewId,
    evaluatorId,
    name,
    role,
  }: {
    reviewId: number
    evaluatorId: number
    name: string
    role: string
  }): Promise<void> {
    await this._reviewManagerService.api.reviewsControllerUpdateEvaluator(
      reviewId,
      evaluatorId,
      {
        name,
        role,
      },
    )
  }

  async createReviewUpdate(reviewId: number) {
    return this._reviewManagerService.api.reviewsControllerCreateReviewUpdate(
      reviewId,
    )
  }
}
